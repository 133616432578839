.scroll-hide {
    overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

 .custom-hover-class:hover {
     color: white;
     background-color: mainBlue;
     fontSize: {
       md: "xl"
     };
     transition: "all 0.2s ease-in-out",
   }
 