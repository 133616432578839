a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

h1 {
    font-size: 2rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

h2 {
    font-size: 1.75rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
}

h4 {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.2;
}

h5 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
}

h6 {
    font-size: .75rem;
    font-weight: 300;
    line-height: 1.2;
}