@import url("https://fonts.googleapis.com/css2?family=Tauri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

@import url("./utils.scss");

$mainBlue: #002855;
// $font : 'Montserrat',
$font: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

html,
body {
  padding: 0;
  font-family: $font;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  text-indent: 0%;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
    -webkit-text-size-adjust: none;
  }

  body {
    color: white;
    background: black;
    -webkit-text-size-adjust: none;
  }
}

// multiselect
.p-multiselect {
  width: 100% !important;
  height: 2.5rem !important;
}

.dropdown-container {
  text-align: initial !important;
}

.dropdown-content {
  z-index: 9999 !important;
}

// loader

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;

  @keyframes animate-stripes {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 60px 0;
    }
  }

  @keyframes auto-progress {
    0% {
      width: 0%;
    }

    20% {
      width: 50%;
    }

    40% {
      width: 70%;
    }

    60% {
      width: 75%;
    }

    80% {
      width: 83%;
    }

    100% {
      width: 100%;
    }
  }

  .progress-bar {
    background-color: #f5f5f5;
    height: 10px;
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 5px;
    box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
  }

  .stripes {
    background-size: 30px 30px;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }

  .stripes.animated {
    animation: animate-stripes 0.6s linear infinite;
  }

  .stripes.animated.slower {
    animation-duration: 1.25s;
  }

  .stripes.reverse {
    animation-direction: reverse;
  }

  .progress-bar-inner {
    display: block;
    height: 10px;
    width: 0%;
    background-color: $mainBlue;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    position: relative;
    animation: auto-progress 3s infinite forwards;
  }
}

.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #bebebe;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    height: 6px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #4299e1;
  border-radius: 10px;
}

.doc-content {
  user-select: contain !important;
}

input {
  outline: 0px !important;
}

// multiselect

div.rmsc.multi-select {
  height: 4rem !important;
  width: 160px;

  .dropdown-container {
    height: 3rem !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; 
    z-index: 9999;

    .dropdown-heading {
      margin-top: 0.2rem !important;

      // place holder color
      &::placeholder {
        color: #718096;
      }
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.smooth_hide {
  transition: all 0.5s ease-in-out;
  transform: translate(0, -100%);
}

.smooth_show {
  transition: all 0.5s ease-in-out;
  transform: translate(0, 0);
}

.sty {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: $mainBlue;
  transform-origin: 0%;
  z-index: 9999;
}

.smooth-osilation-down {
  animation: downmotion 2s linear infinite forwards;
}

// homepage down animation

@keyframes downmotion {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }

  70% {
    opacity: 0.6;
  }

  100% {
    transform: translateY(0px);
  }
}

// ________________________________________________________
/*
 * CKEditor 5 (v36.0.1) content styles.
 * Generated on Wed, 08 Feb 2023 13:09:39 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
  table-layout: fixed;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  position: relative;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
